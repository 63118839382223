<template>
  <section
    id="main-desc"
    style="background-color: #f0f4fa;"
  >
    <base-left-photo
      :src="require('@/assets/cover.jpg')"
      subheading="NITRILE EXAMINATION GLOVES"
      html="Our current product range includes superior quality nitrile examination gloves, certified for food handling according to 21 CFR 177.2600 safe for food contact gloves, and chemotherapy drugs handling gloves on permeation tested as per ASTM D6978-05."
      color="black--text"
    >
    </base-left-photo>
    <base-right-photo
      :src="require('@/assets/main_gloves_3.png')"
      subheading="HOWELLCARE INDUSTRIES"
      html="At Howellcare Industries Sdn Bhd, we deliver excellence in manufacturing international standard nitrile gloves for personal barrier protection. Equipped with core competencies in nitrile glove production line design, fabrication, installation, commissioning, production and quality control, we are committed to our passion to deliver superior quality products and services to the nitrile glove manufacturing industry."
      color="black--text"
    >
    </base-right-photo>
    <base-left-photo
      :src="require('@/assets/technology-1.jpg')"
      subheading="INNOVATIVE TECHNOLOGY"
      html="Our commitment towards innovation and continuous improvement of our products and processes through research and development efforts offers a competitive edge in the industry."
      color="black--text"
    >
    </base-left-photo>

      <v-row
    class="px-0 mx-0"
    style="background-color: #ebedee;"
  >
    <v-col
      cols="12"
      sm="6"
      class="px-0 mx-0"
    >
      <v-responsive
        class="d-flex align-center mx-auto"
        height="100%"
        max-width="800"
        width="90%"
      >
        <base-subheading
          weight="regular"
          title="INTERNATIONAL INDUSTRY STANDARD"
          class="text-center font-weight-bold"
        />
        <br>
        <p class="mx-10 black--text">
          We uphold stringent quality control of international standards to ensure the best user experience and protection. Suitable for household, industrial and medical purposes, we deliver with pride our nitrile glove products for the safety and hygiene of our customers.
        </p>
        <br>
      </v-responsive>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      class="px-0 py-0 mx-0"
    >
      <v-img
        :src="require('@/assets/howellcare_main4.jpeg')"
        :height="$vuetify.breakpoint.mdAndUp ? 400 : 225"
        contain
      >
      </v-img>
    </v-col>
  </v-row>
  </section>
</template>

<script>
  export default {
    name: 'SectionMainDesc',
  }
</script>
